.dltBtnDiv{
    align-items: end;
    margin-bottom: 20px;
    margin-left:85%;
}

.dltBtn{
    text-decoration: underline;
}

.addressDiv{
    margin-bottom: 70px;
    background-color: #F6F7FC;
    width:95%; 
    margin-left: 20px;
    border-radius: 2rem;
    padding-bottom: 5px;
}

.textalign{
    font-weight:bold;
    text-align: center !important;
}

.inputForm1{
    width:125px;
    margin-left:30px; 
}

.inputForm2{
    width:400px;
    margin-left:10px; 
}
.addBtn{
    position: relative;
    top:10px;
    left: 10px;
}
.actionBtns{
    padding:0;
   
}
.editDelBtns{
    position: relative;
    left:2rem;
}

.pagination{
    display: flex;
    justify-content: center;
}

.paginationBtn{
    background-color: white;
    border: none;
    margin: 0 20px;
}

:hover.paginationBtn{
    background-color: #004747;
    color:white;
    border-radius:10rem;
}

.actBtn{
    border: none;
}

.searchBar{
    width:350px !important;
    border-radius: 3rem;
    padding:10px 24px 12px 26px;
}

.editBtn{
    position: relative;
    left: 30px;
}

.delBtn{
    position: relative;
    left: 30px;
}

.colWidth{
    width:12rem;
}

.colLinkWidth{
    width:7rem;
}
.tableRow {
    border-bottom: 1px solid #ddd;

}

.search-content{
    display:flex;
    justify-content: space-between;
    margin-top:36px;
}

.bulk-address-data{
    margin-top:30px;
}

.preview-btn{
    background-color: #004747;
}


.preview-btn:hover{
    background-color: #004747;
}
.preview-btn:active{
    background-color: #004747!important;
}


.main-form-head{
    margin-top:20px;
}

.delete-content .btn span{
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-decoration: underline;
    text-align: left;
}

.edit-pagination{
    margin-top:28px;
    display:flex;
    justify-content: center;;
}

.bulk-address-data-table{
    padding: 24px 25px;
    background: #fff;
    border-radius:10px;
}
.bulk-address-data-table th, .bulk-address-data-table td{
 width:10%;
}
.address-table th,.address-table td{
    width:11%;
}
.bulk-address-data-table table thead tr th{
    font-family: 'Satoshi Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color:#032020;
}


.bulk-address-data-table table tbody tr td{
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color:#032020;
    word-wrap: break-word;
}

.bulk-address-data-table table th:nth-child(8),
.bulk-address-data-table table th:nth-child(9),
.bulk-address-data-table table th:nth-child(10),
 .bulk-address-data-tabletable th:nth-child(11),
 .bulk-address-data-table table td:nth-child(8),
 .bulk-address-data-table table td:nth-child(9),
 .bulk-address-data-table table td:nth-child(10),
 .bulk-address-data-table table td:nth-child(11) {
  text-align: center;
}
.bulk-address-data-table table tbody tr td .preview-btn{
    position: relative;
    top: 0;
    padding:3px 12px 7px 12px;
}
.babyModal-img img{
    margin-top: 7px;
}

.bulk-address-data-table table tbody tr td .btn{
    border:none;

}
.bulk-address-data-table table tbody td .actionBtns img{
    position: relative;
    top: -3px;
}
 .bulk-address-data-table table tbody td svg{
    margin-top:0;
}
@media only screen and (max-width: 768px) {
    .bulk-address-data{
        overflow-x: scroll;
        background-color: #fff;
        border-radius: 10px;
    }
    .search-content {
        flex-direction: column;
    }
    .searchBar{
     width: 85% !important;
    }
    .bulk-address-data-table{
        overflow-x: scroll;

    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .bulk-address-data-table {
        overflow-x: scroll;
    }
}