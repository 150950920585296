.mainDiv {
  display: flex;
  height: 100vh;
}
.contentDiv {
  margin-left: 11%;
  width: 88%;
  transition: margin-left 0.3s ease, width 0.3s ease;
  height: 100vh;
  overflow-y: auto;
  background-color: #f6f7fc;
}
.dataTable {
  align-items: center;
  border-radius: 10px;
  padding: 24px 24px;
  background-color: #f6f7fc;
}
.dataTableHeading {
  padding: 0 24px;
  border-bottom: 1px solid #032020;
}
.floor-overlay {
  margin-right: -16px;
}
.pointer {
  cursor: pointer;
}
.overlay-image {
  position: relative;
}
.expanded {
  margin-left: 5%;
  width: 95%;
}

.maintable {
  background-color: white;
  padding: 30px 0;
  border-radius: 10px;
}
.maintable h2 {
  font-family: "Satoshi Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #302030;
  padding-bottom: 16px;
  margin-bottom: 0;
}
.nen2580Class {
}
.wwsClass {
}
.epwClass {
}
.floorClassName {
}

.columnDropdown {
  right: 0;
  left: auto;
  transform: translateX(0);
  position: absolute;
  top: 100%;
  z-index: 1000;
  white-space: nowrap;
  max-width: 300px;
  overflow-x: hidden;
  box-sizing: border-box;
  width: 270px;
  max-height: 80vh;
  overflow-y: auto;
  margin-top: 40px;
}
.selection-li-content {
  overflow: scroll;
  height: 334px;
  background: rgba(227, 246, 244, 0.2);
  border-radius: 20px 20px 0px 0px;
  margin-top: 15px;
}
.selection-container-content label {
  padding: 10px;
}

.formHead {
  padding: 0 24px;
}
.mainWrap {
  position: relative;
}
.quoteBody {
  width: 100%;
  display: flex;
}
.mainWrap .portfolio {
  position: absolute;
}
.mainWrap .sidebar {
  display: block;
}

.mainWrap .portfolio {
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-left: 54px;
  transition: left 0.5s ease;
}
.portfolioChecked {
  left: 0;
}

.portfolioUnchecked {
  left: 150px;
}

.pageBody.contentHt {
  height: 100vh;
}

.pageBody {
  padding: 24px 24px;
  background-color: #f6f7fc;
}

.jsonListRecord {
  background-color: white !important;
  padding: 30px 0;
  border-radius: 10px;
}

img.sidebarToggle {
  position: fixed;
  top: 39px;
  left: 20px;
}

input#slideSidebar {
  display: none;
}

.mainWrap label {
  z-index: 2;
  top: 0;
  left: 150px;
  padding-bottom: 8px;
  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;
}

.mainWrap label:hover {
  cursor: pointer;
}

.mainWrap .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }

  .mainWrap .portfolio {
    margin-left: 0 !important;
    left: 0 !important;
    top: 60px !important;
  }

  .menus {
    display: none;
    margin-top: 0;
  }

  .menus.visible {
    display: block;
    background-color: #f6f7fc;
    padding: 12px 20px;
  }
  .sidebar {
    position: relative;
  }
  img.sidebarToggle {
    display: none;
  }

  .toggleBtn {
    display: block;
    background: none;
    border: none;
    font-size: 24px;
    margin: 10px;
    width: 10%;
  }
  .child1 {
    position: relative;
  }
  .child2 {
    position: relative;
    margin-top: 24px;
  }
  .sidebarContent {
    width: 100%;
  }
  .main-wrap .sidebar {
    position: relative;
  }
}
