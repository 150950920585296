body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Dashboard  */

.dashboard-details {
  padding: 0 24px;
}

.portalParentDiv{
  display: flex;
  width: 100%;
  margin-top: 35px;
}
  
.portalParentDiv1{
  width: 100%;
  margin-right: 20px; 
  border: 1px solid #d5d0d0!important;
}    
.dataDiv{
  width: 55%;
  font-size:15px
}


@media only screen and (max-width: 768px) {
  .portalParentDiv1{
    margin-right: 0px; 
  }
  .dataDiv{
    width: 55%;
    font-size:10px
    }
    
}