
.app {
  display: flex;
}

.content {
  flex: 1;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-body{
  padding: 50px;
  border: #282c34 solid;
}
.togglebtn{
  position: relative;
  top:20px;
}

.btn1{
  position: relative;
  left:20px;
}

.notification-card {
  position: fixed;
  top: 60px;
  right: 60px;
}

.separator {
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 10px 0;
}

.section {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.scrollable-container {
  max-height: 600px; /* Set the desired height for the container */
  overflow: auto;
}

.custom-button {
  width: 117px;
  height: 32px;
  padding: 6px 24px;
  border-radius: 25px;
  gap: 6px;
  color: gray;
  background: #F6F7FC;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: auto;
  padding: 0 20px;
  z-index: 1000 !important;
}

.filterRow{
  margin-bottom: 30px;
}

.searchInput {
  width: 320px;
  height: 32px;
  border: none;
  border-radius: 50px;
  background: #F6F7FC; 
  margin-left: auto; 
}

.filterStyle {
  width: Hug (117px);
  height: Hug (32px);
  padding: 6px 24px 6px 24px;
  border-radius: 25px;
  gap: 6px;
  background: #F6F7FC;
  border: none;
}

.zIndex {
  position: absolute;
  z-index: 1;
}

.filterImage {
  width: 50px;
}

.line-row {
  border-bottom: 1px solid #03202026; /* Change the style and color to match your design */
}

.pageSize{
 margin-left:80rem;
 position: relative;
 bottom: 1.5rem;
}

.tableColWidth{
  width:220px;
}

.tableColWidth1{
  width:241px;
}

.th1{
  text-align: left;
}

.th2{
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .btn1{
    left:0;
  }
}

