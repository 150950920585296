.dataTableContainer {
  padding:16px 24px;
}

.dataTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
}
.tableHeadRow {
  background-color: #f2f2f2;
  position: relative;
}
.dataTable th,
.dataTable td {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  padding: 2px 20px;
  opacity: 60%;
  white-space: nowrap;
}

.dataTable td {
  opacity: 100%;
  height: 39px;
  border-bottom: #e8e6e6 solid 1px;
  z-index: 999;
  position: relative;
}
.dataTableContainer table tbody tr{
  position: relative;
}
.imageCell img {
  max-width: 100px;
  height: auto;
}

.headerCell {
  text-align: center;
}

.headerImage {
  width: 20px;
  height: auto;
}
.streetHouseNo {
  width: 25%;
  text-align: left;
}
.NEN2767 {
  width: 25%;
  text-align: left;
}
.NEN2580 {
  width: 25%;
  text-align: left;
}
.WWS {
  width: 20%;
  text-align: left;
}
.EPW {
  width: 15%;
  text-align: left;
}
.floorPlan {
  width: 15%;

  text-align: left;
}
.arrowUp, .arrowDown {
  cursor: pointer;
  padding-left: 3px;
  text-align: center;
  line-height: 20px;
  font-size: 7px;
  color: #032020;
  margin-top: -14px;
}

.tableArrow{
  display: inline-block;
}
.collapsableColumn{
  position: absolute;
  right: 4px;
  z-index: 1000;
  padding: 2px 20px;
}
@media only screen and (max-width: 768px) {
  .dataTableContainer{
    overflow-x: scroll;
  }
}