body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.quote-scan-record {
  margin-top: 24px;
  /* padding: 25px; */
  padding-top: 25px;
  padding-bottom: 25px;
  .selected-partners-list {
    margin: 15px 5px;
  }
}

.tab input:checked ~ .tab-content {
  max-height: max-content;
}

/* Visual styles */

.tab-content p.message {
  margin: 0;
  padding: 10px 24px 16px 6px;
}


.main-record {
  width: 100%;
}

.quote-outside-record,
.quote-outside-extras-record,
.quote-inside-services-record,
.quote-inside-summary-record,
.quote-planning-record,
.quote-outside {
  background-color: white;
  padding: 30px 0px;
  border-radius: 10px;
  margin-top: 24px;
  /* overflow-x: scroll; */
}

.rows-content.padding {
  padding: 0;
}
input#cb1, input#cb10, input#cb2, input#cb3, input#cb4, input#cb5, input#cb6, input#cb7, input#cb8, input#cb9 {
  display: none;
}

input[type="checkbox"]::before {
  content: "\2713";
  display: block;
  position: absolute;
  left: 42%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #fff;
  visibility: hidden;
  width: 6px;
  top:10px;
}

.main-wrap {
  position: relative;
}

.blur {
  filter: blur(1px);
}

.loader-overlay {
  position: relative;
  top: 700px;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.CheckInputOther {
  margin-bottom: 20px;
}

.InsidePopTHfirst {
  text-align: left;
  padding: 5px;
}
.InsidePopTH {
  text-align: center;
  padding: 5px;
}
.inspPopCostTH,
.insidePopCostTH {
  text-align: right;
  padding-right: 0;
  width: 20%;
}
.editSidebar{
  margin: 0;
  position: sticky;
  top: 0px;
  background: #f6f7fc;
  padding: 25px 14px;
  z-index: 999;
}

@media only screen and (max-width: 768px) {
  .formDivquote{
    flex-direction: column;
  }
  .formDivquote .main-record{
    width: 100% !important;;
  }
  .editSidebar{
   overflow-x: scroll;
  }
  .tab-label h2 {
    font-size:22px;
  }
}


