.plusBtn{
    border:none;
    bottom:70px;
}

.resultDiv span h4{
    font-family: 'Satoshi Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    color: #032020;
    margin-bottom:0;
}

.resultDiv span p{
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color: #032020;
    opacity: 60%;
    margin-bottom:0;
}

.intro{
    margin-left: 20px;
    margin-top: 2px;
}

.resultDiv{
    display: flex;
    justify-content: space-between;
    gap: 12px;
    background: #F6F7FC;
    padding: 12px 12px;
}

.showMoreBtn{
    width:100%;
    background-color: #F6F7FC;
    margin-right: 24rem;
    margin-top:0.6rem;
}

.showMoreBtn:hover{
    background-color: black;
    color:white;
}

.showLessBtn{
    width:100%;
    background-color: #F6F7FC;
    margin-right: 24rem;
    margin-top:0.6rem;
}

.showLessBtn:hover{
    background-color: black;
    color:white;
}

.search-inviteuser{
    position: relative;
    box-shadow: none;
}

.form-control:focus{
    box-shadow: none!important;  
}

.result-data{
    position: absolute;
    margin-top:38px;
    display: flex;
    z-index: 1000;
    background-color: #fff;
    flex-direction: column;
    gap: 14px;
    padding: 24px 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.result-data:empty {
  box-shadow: none;
  background: none!important;
}
