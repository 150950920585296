.quote-planning-record .tab-content{
  padding:10px 24px;
  display: flex;
  flex-direction: column;
}

.planning-headers label{
  font-family: 'Satoshi Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0em;
  color:#032020;
  padding:0;
}

.planning-content .first-row{
  display: flex;
  gap: 16px;
  padding: 16px 0;
  margin-top:0;
}

.planning-content .rows-content .date {
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  color: #032020;
  background-color: #F6F7FC;
  height: 50px;
  padding: 0;
}

.date-range{
  padding: 8px 16px;
  border: none;
  background: none;
}

.no-selection .tab {
  border: 1px solid  #C70039;
  border-radius:5px;
  padding: 14px 0;
}

.no-selection .form-head.quote-detail {
  border-bottom: none; 
}

.no-selection .form-head.quote-detail h2{
  padding-bottom:0;
}

.errormessage {
  color: #C70039;
  position: relative;
  bottom:22px ;
}