body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.project-creation-container {
    position: relative;
}
  
.blur {
    filter: blur(1px);
}

.loader-overlay {
    position: relative;
    top: 700px;
    left: 100%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.rows-content.error input[type="text"],
.rows-content.error input[type="email"],
.rows-content.error input[type="password"],
.rows-content.error select {
  border: 1px solid  #C70039;
}

.rows-content.error .select{
  border: 1px solid  #C70039;
}

.rows-content.error .date{
  border: 1px solid  #C70039;
}

.error {
  color: #C70039;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.error-message {
  color:  #C70039;
  font-size: 12px;
}