body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Project Details with Project Leaders Record */

.project-details-record, .user-details-record, .organization-details-record, .quote-details-record,
.quote-scan-record {
  background-color: white;
  padding: 30px 0px;
  border-radius:10px;
}

.form-head.project-details, .form-head.user-details, .form-head.admin-details{
  border-bottom: 1px solid #032020;
  padding: 0 24px;
}

.form-head.project-leader{
  border-bottom: 1px solid #032020;
  padding: 0 24px;
}

.project-leaderdetails-record {
  background-color: white;
  padding: 30px 0px;
  border-radius:10px;
  margin-top:24px;
}

.project-visible-record{
  margin-top:24px;
}

.project-visible-record {
  background-color: white;
  padding: 30px 24px;
  border-radius:10px;
  margin-top:24px;
}

.project-visible-record .data-toggle{
  display:flex;
  gap:24px;
}

.project-visible-record .data-toggle .form-switch{
  padding:0;
}

.project-visible-record .data-toggle .form-switch .form-check-input{
  margin:0;
}
  
.project-visible-record .data-content span{
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color:#032020;
}

.project-visible-record .data-content p{
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color:#032020;
  margin-top:4px;
  margin-bottom:0;
  opacity:60%;
}

.project-creation-container {
  position: relative;
}

.blur {
  filter: blur(1px);
}

.loader-overlay {
  position: relative;
  top: 700px;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

