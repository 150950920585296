img{
    border: none;
}

.form-head .list-address-build{
    display: flex;
    justify-content: space-between;
}

.main-form-head{
    margin-top:20px;
}
.defaultExploreButtoncolor{
    color:grey;
}
.building-name{
    color:blue;
    cursor: pointer;
    text-decoration: none;
}
.building-documents{
    color:grey;
}
.download-arrow{
    color: grey;
    align-items:center;
    margin-top:7px;
    cursor: pointer;
}
.cancel-documents{
    color: grey;
    margin-top: 4px;
    width: 15px!important;
    cursor:pointer;
}
.excel-download,.cancel-icon{
    color:grey;
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    .form-head .list-address-build{
        margin-top:11px;
    }
}
