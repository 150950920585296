input#slideSidebar {
  display: none;
}

.sidebarContent {
  width: 10% !important;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.messageBox {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  color: #333;
}
.completeMessageSpinner {
  margin-left: 40%;
}
 label {
  z-index: 2;
  top: 0;
  left: 150px;
  padding: 10px;
  transition: left 0.5s ease;
}
 .sidebar {
  display: block;
}
 .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.sidebarContent {
  width: 10%;
  /* padding: 30px 24px; */
}

.sidebarContent .sidebar ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 0;
}


.sidebarContent .sidebar ul li {
  display: flex;
  gap: 16px;
  right: 10px;
  position: relative;
}

.sidebarContent .sidebar ul li span {
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 4px;
  opacity: 0.7;
}

.projecticon {
  opacity: 0.3;
}

.sidebarContent .sidebar ul li a {
  color: #032020;
  opacity: 0.6;
  text-decoration-line: none;
}

.sidebarContent .sidebar ul li.activeuser a {
  color: #ff6b00;
}

.sidebarContent .sidebar ul li.active a {
  color: #021a1a;
  opacity: 100%;
}

.sidebarContent .sidebar ul li.active span {
  font-weight: bold;
}

.sidebarContent .sidebar ul li.active .projecticon {
  opacity: 1;
}

.menus {
  position: relative;
  margin-top: 147px;
}

.child1 {
  position: fixed;
  left: 0;
  padding: 0 24px;
}

.child2 {
  bottom: 0;
  position: fixed;
  left: 0;
  padding: 0 24px;
  margin-bottom: 40px;
}

.sidebarContent .sidebar ul li img {
  width: 24px;
  height: 25px;
}
.toggleBtn{
  display: none;
}

.toggleBtn {
  width: 100%;
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
  text-align: left;
}


.jsonSidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.jsonSidebar{
  display: block;
}

 .sidebar h1 {
  color: white;
}

 
.mainWrap .portfolio {
  background-color: white;
  position: absolute;
  top: 0;
  left: 150px; /* Default position */
  right: 0;
  bottom: 0;
  margin-left: 54px;
  transition: left 0.5s ease; /* Smooth transition */
}

input:checked ~ label {
  left: 0;
}
img.sidebarToggle{
  position: fixed;
  top: 39px;
  left: 20px;
}

input#slideSidebar {
 display: none;
}


 label {
  z-index: 2;
  top: 0;
  left: 150px;
  padding:10px;
  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;
}

 label:hover {
  cursor:pointer;
}


@media (max-width: 768px) {
  .sidebar {
      width: 100%;
      position: relative;
  }
  .mainWrap .portfolio{
    position: relative;
    margin-left:0;
    left:0;
  }

  .menus.visible {
      display: block;
      background-color: #f6f7fc;
      padding: 12px 20px;
      
  }
  .sidebar {
    position: relative;
  }
  img.sidebarToggle{
    display: none;
  }

  .toggleBtn {
      display: block;
      background: none;
      border: none;
      font-size: 24px;
      margin: 10px;
      width:10%;
  }
  .child1 {
      position: relative;
  }
  .child2 {
      position: relative;
      margin-top: 24px;
  }
  .sidebarContent{
      width:100%;
  }

}

