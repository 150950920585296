.list {
  padding: 16px 24px 16px 24px;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  white-space: nowrap;
  transition: overflow-x 0.3s ease-in-out;
}

.list.scroll-enabled {
  overflow-x: scroll !important;
}

.theadColumn {
  height: 40px !important;
}

.recordRow {
  background: #f2f2f2;
  position: relative;
  border-bottom: 1px solid rgba(3, 32, 32, 0.15);
}
.emptyColumn {
  opacity: 1;
  background: #f2f2f2;
  border: none;
  padding: 0;
  position: relative;
}
.projectlistDetailsTableContent .tableContent .list table tbody tr {
  border-bottom: 1px solid rgba(3, 32, 32, 0.15);
  cursor: pointer;
  height:34px;
}
.projectlistDetailsTableContent .tableContent .list table tbody tr:hover {
  background-color: #d3d3d3;
}

.projectlistDetailsTableContent .tableContent .list table thead tr th {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  opacity: 60%;
  padding: 2px 9px;
  height: 39px;
  white-space: nowrap;
}

.projectlistDetailsTableContent .tableContent .list table tbody tr td {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  padding: 2px 9px;
}

.jsonMainColumnSelection {
  right: -13px;
  top: -2px;
  padding: 2px 20px;
}

.selectionButton {
  cursor: pointer;
  border: none;
  background: none;
}

.forNameRowOpac {
  width: 25%;
  text-align: left;
  padding: 2px 20px;
}
.boldText {
  font-weight: bold !important;
}
.extraWidth {
  width: 2%;
}
.marginColumn {
  margin-top: 0;
}
.elementRow {
  text-align: left;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  width: 160px;
  margin-top: 6px;
}
/* Tooltip Styling */
.elementRow:hover::after {
  content: attr(data-full-text);
  position: absolute;
  left: 120px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 6px 10px;
  border-radius: 5px;
  white-space: normal;
  width: max-content;
  max-width: 300px;
  word-wrap: normal;
  z-index: 10;
  margin-top: -27px;
}
.typeRow {
  text-align: left;
}
.locationRow {
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 150px;
}
.detectionRow{
  text-align: center;
}
.viewRow {
  width: 5%;
  text-align: center;
}

.viewRow a {
  display: inline-block;
  border-radius: 6px;
  background: #f6f7fc; /* Default background color */
  padding: 5px;
  transition: background-color 0.3s ease;
}

.viewRow a:hover {
  background-color: #032020; /* Change background to green when clicked */
  outline: none; /* Remove the default focus outline */
}

/* Default image - shown initially */
.viewRow a img:first-of-type {
  display: block;
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* Active image - hidden initially */
.viewRow a img:last-of-type {
  display: none;
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* Toggle visibility when the link is active */
.viewRow a:hover img:first-of-type {
  display: none;
}

.viewRow a:hover img:last-of-type {
  display: block;
}

/* .rightColumn{
  padding-right:3%!important;
} */
.lengthRow {
  text-align: center;
}
.areaRow {
  text-align: center;
}
.quantityRow {
  text-align: center;
}
.lengthTotalRow {
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 150px;
}
.grossRow {
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 150px;
}
.netRow {
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 150px;
}
.deviationRow {
  text-align: left;
}
.explanationRow {
  text-align: left;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  width: 160px;
  margin-top: 5px;
}

/* Tooltip Styling */
.explanationRow:hover::after {
  content: attr(data-full-text);
  position: absolute;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 6px 10px;
  border-radius: 5px;
  white-space: normal;
  width: max-content;
  max-width: 300px;
  word-wrap: break-word;
  z-index: 10;
  top: 0;
  transform: translateY(100%);
}

.locationRoofRow {
  text-align: left;
}
.facadeRow {
  text-align: left;
}
.intensityRow {
  text-align: left;
}
.extentRow {
  text-align: left;
}
.aspectRow {
  text-align: left;
}
.priorityRow {
  text-align: center;
}

.tableHeader {
  display: inline-flex;
  align-items: center; /* Keep text and arrows in the same row */
  gap: 1px;
}
.showTotalRow {
  background-color: #f2f2f2;
}
.showTotalItem {
  text-align: center;
}
.botImage {
  width: 24px;
  height:24px;
}

@media screen and (max-width: 1024px) and (max-height: 768px) {
  .list {
    overflow-x: scroll;
  }
}

@media (max-width: 1600px) {
  .list.scroll-enabled {
    overflow-x: scroll !important;
  }
}
