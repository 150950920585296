.formHead {
  padding: 0 24px;
  border-bottom: 1px solid #032020;
}

.jsonReport {
  display: flex;
  justify-content: space-between;
}

.buildingDetailsSubData {
  display: flex;
  flex-direction: row;
  gap: 45px;
  position: relative;
  top: -3px;
}

.buildingDetailsSubData h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  font-family: "Satoshi Medium";
  line-height: 16.8px;
  letter-spacing: 0em;
  text-align: left;
  color: #302030;
}

.buildingDetailsSubData p {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 18.8px;
  white-space: nowrap;
  color: #032020;
}

.buildingDetailsSubDataResult p {
  font-weight: 500;
}

.buildingDetailsHeading {
  display: flex;
  gap: 6px;
  align-items: center;
}

.buildingDetailsHeading h2,
.reportDetailsHeading h2,
.refreshtDetailsHeading h2 {
  font-family: "Satoshi Medium";
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
  margin-bottom: 0;
  color: #032020;
}
.refreshtDetailsHeading h2 {
  text-align: center;
}
.buildingDetailsData {
  display: flex;
  margin-top: 33px;
  gap: 3%;
}

.buildingRecord {
  display: flex;
  gap: 30px;
}

.reportDetails {
  display: flex;
  gap: 24px;
}

.reportDetailsHeading {
  display: flex;
  gap: 6px;
  align-items: center;
  position: relative;
  right: -18px;
}

.reportDetailsContentsubData {
  display: flex;
  gap: 55px;
  background-color: #f2f2f2;
  padding: 15px;
  border-radius: 12px;
}

.reportDetailsContentSubData {
  display: flex;
  gap: 45px;
  margin-top: 18px;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 12px 18px;
}

.reportDetailsContentSubData p {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 18.8px;
  white-space: nowrap;
  color: #032020;
}

.reportDetailsContentSubDataResult p {
  font-weight: 500;
}

.refreshDetailsContent {
  margin-top: 18px;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 15px 15px 17px 15px;
}

.refreshDetailsContent div {
  background: #cf8e00;
  padding: 10px 13px;
  border-radius: 50%;
  width: 49px;
  height: 49px;
}
.refreshDetailsContent div img {
  cursor: pointer;
}

.babyModalImg {
  border-radius: 70px;
  height: 116.17px;
  width: 117px;
  margin: 0;
  shape-outside: circle();
  clip-path: circle();
}
.jsondeleteReport{
  display: flex;
  justify-content: space-between;
}
.jsonDeleteButton{
  background: #004747;
  margin-top: 8px;
  border-radius:50px;
  padding:6px 12px;
  border:1px solid #004747;
}
.jsonDeleteButton span{
  font-family: "Satoshi Medium";
  font-weight: 500;
  font-size: 14px;
  padding: 2px 15px 3px;
  color:#fff;
}
.deleteDetails{
 padding-bottom:15px;
}
@media screen and (max-width: 1024px) and (max-height: 768px) {
  .jsonReport {
    overflow-x: scroll;
    gap: 40px;
  }
}
@media (max-width: 1600px) {
  .jsonReport {
    gap: 80px;
  }
}

.disabledButton {
  pointer-events: none;
  cursor: default;
}
