
/* list org */

.form-head.list-org{
  border-bottom: 1px solid #032020;
  padding:0 24px;
}


.organizationlist-details-content .content-options .content-button.search{
  padding:11px 24px;
}


.organizationlist-details-content .content-options .content-button select{
  border:none;
  outline: none;
}

table{
  width:100%;
}

.organizationlist-details-content .user-select{
  border:none;
}

.organizationlist-details-content .licence-style{
  border:none;
}


.checkInputManageOrgList{
  top: 0!important;
}

.organizationlist-details-table-content .ForNameRow{
  width:30%;
  text-align: left;
}
.organizationlist-details-table-content .ForCityRow{
  width:30%;
  text-align:left;
}
.organizationlist-details-table-content .ForDateRow{
  width:20%;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .organizationlist-details-content{
   overflow-x: scroll;
  }
  
  .organizationlist-details .form-head.list-org h2{
    font-size:14px;
  }
  .create-org-btn .btn  a{
    font-size:11px;
  }
  }


