body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* user create record */

.user-details .rows-content .date{
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color:#032020;
  background-color: #F6F7FC;
  height:50px;
  padding:8px 16px;
  border-radius:5px;
}

.user-details .rows-content .date .react-datepicker__input-container input[type="text"]{
  height:27px;
  padding:0;
  border:none;
  margin-top:0;
  outline:none;
}

.user-details .rows-content .select{
  font-family: 'Satoshi Medium';
  text-align: left;
  margin-top:8px;
  border:none;
  color:#032020;
  margin-top:8px;
  height:50px;
  padding: 0;
  background: #F6F7FC;
  font-size: 16px;
  -webkit-appearance: auto;
}

.user-details .rows-content .form-control {
    height:50px;
    padding: 8px 16px 8px 16px;
    font-weight: 500;
}

.user-details .rows-content select {
  height: 50px;
  background: #F6F7FC url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6 8l4 4 4-4" stroke="%23333" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat;
  background-position: right 16px center;
  background-size: 23px; 
  padding:8px 16px 8px 16px;
  cursor: pointer;
  border-radius:5px;
}

.required{color:red;}

.project-creation-container {
  position: relative;
}

.blur {
  filter: blur(1px);
}

.loader-overlay {
  position: relative;
  top: 700px;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
