.planning-content.drone.planning-content .first-row {
  display: flex !important;
  align-items: center!important;
  width: 100%!important;
}

.planning-content.drone.planning-content .first-row.plan {
 padding:0;
}
.quote-details .first-row .rows-content, .quote-details .second-row .rows-content{
  width: 50%!important;
}


.no-selection .tab {
  border: 1px solid  #C70039;
  border-radius:5px;
  padding: 14px 0;
}


.message {
  color: #C70039;
  padding-top: 6px;
  font-weight: 700;
  font-size: 0.8rem;
  margin-top:0.2rem;
  padding-left: 25px;
}

.FirstRowDrone{
  padding: 0!important;
}