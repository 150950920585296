
/* List Project */

.quotelist-details-content .content-options .content-button.search{
  padding:11px 11px 11px 16px;
}

.redirectBtn{
  text-decoration: underline;
  cursor: pointer;
}

.quotelist-details-table-content .ForNameRow{
  width:30%;
  align-items:left;
}
.quotelist-details-table-content .ForStatusRow{
  width:20%;
  align-items: left;
}
.quotelist-details-table-content .ForDateRow{
  width:20%;
  align-items: left;
}
@media only screen and (max-width: 768px) {
  .quotelist-details-content{
    overflow-x: scroll;
  }
}