.dropdownArrow {
  width: 15px;
}
.list{
  padding:16px 24px;
}
.theadColumn {
  height: 40px;
}
.recordRow{
  background: #f2f2f2;
  position: relative;
  border-bottom: 1px solid rgba(3, 32, 32, 0.15);
}
.clickedRow{
  background-color: #d3d3d3;
}
.dropdownBtn{
  position: relative;
  background: none;
  border:none;
}

.rotated {
  transform: rotate(180deg);
}
.downImg {
  width: 20px;
  transition: transform 0.3sease-in-out;
  cursor: pointer;
}
.downImg:focus{
  border:none;
}
.reportListDetailsTableContent table thead tr, .reportListDetailsTableContent table tbody tr{
  border-bottom:1px solid rgba(3, 32, 32, 0.15);
}
.reportListDetailsTableContent table thead th{
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  opacity: 60%;
  padding: 2px 20px;
  height: 39px;
  white-space: nowrap;
}
.reportListDetailsTableContent table tbody td{
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  padding: 2px 20px;
  height:40px;
}
.reportListDetailsTableContent table tbody  .dropDownRow td table tr{
  border-bottom: 2px solid #fff;
  background-color: #f2f2f2;
}
.tableData_reportListDetailsTableContent__UUGp6 table tbody.dropDownRow td{
  padding: 0;
  width: 12.11%;
  border-radius: 12px;
}
.ForProjectRow{
  width: 25%;
  text-align: left;
}
.FororganizationNameRow{
  width: 25%;
  text-align: left;
}
.ForProjectRoleRow{
  width: 20%;
  text-align: left;
}
.ForStartRow{
  width: 15%;
  text-align: left;
}
.ForEndRow{
  width: 15%;
  text-align: left;
}
.tableHeader{
  display: inline-flex;
  align-items: center;
  gap: 1px;
  }
.dropDownRow td{
  padding:0!important;
  width: 12.11%;
  border-radius: 12px;
}

@media (max-width: 1600px) {
  .ForProjectRow {
      width: 45%;
  }
}