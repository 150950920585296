.dropzone {
    padding: 20px;
    text-align: center;
    cursor: pointer;
    background-color: #F6F7FC;
    margin-top:24px;
  }


.dropzone h4{
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color:#032020;
    margin-top:8px;
    margin-bottom:0;

}  

.dropzone p{
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color:#032020;
    opacity: 60%;
    margin-top:4px;
    margin-bottom:0;

}

.main-file-content{
    display: flex;
    justify-content: space-between;
    padding: 12px 12px;
}

.main-file-content .sub-file-content{
    display: flex;
    gap: 12px;
}

.main-file-content .sub-file-content ul{
  padding-left:0;
}

.main-file-content .sub-file-content ul li h5{
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color:#032020;
  margin-bottom:0;
}

.main-file-content .sub-file-content ul li p{
  font-family: 'Satoshi Medium';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color:#032020;
  opacity:60%;
}

.closeBtn.bulk-upload{
  float:right;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.bulkUploadBtn{
  border-radius: 50px;
 }
  
.closeBtn{
  border: none;
  background:none;
  align-self: flex-end;
  cursor: pointer;
 }

 .submit-btn{
  margin-top: 20px;
  width: 100px;
  border-radius: 10rem;
 }
 
 .fileDiv{
  width: 100%;
  margin-top:20px;
  margin-left: 1px;
  height:90px;
  background-color: #F6F7FC;
 
 }
 .btn1{
  margin-right: 40px;
 }

 .bin{
  border: none;
  background: none;
 }

 .r1{
  margin-top: 20px;
 }
 
 .r2{
  position: relative;
  right:40px;
 }
 @media only screen and (max-width: 768px) {
  .closeBtn.bulk-upload{
    display: flex;
    gap:12px;
    justify-content: center;
    float: center;
   }
 }
