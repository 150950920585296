.main-outside-content, .quote-inside-summary-record .tab-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;
}

.quote-outside-extras-record .tab-content,
.quote-outside-record .tab-content{
  padding:0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}   

input[type="checkbox"]{
  position: relative;
    top: 5px;
    width: 20px;
}
.tableInputs {
  width: 120px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #b7b6b6;
}
.extraServiceLabel{
  margin-bottom: 15px;
  margin-left: 5px;
}

.AddRowButton{
  padding: 4px;
  border: none;
  width: 52px;
  border-radius: 2px;
  background: #f3f3f3;
  img{
    padding: 0px 5px 3px 0px;
  }
}
.RemoveRowButton{
  padding: 4px;
  border: none;
  width: 52px;
  border-radius: 2px;
  background: #f3f3f3;
  img{
    padding: 0px 5px 3px 0px;
  }
}

.CheckInputInsProductExtServ{
  position: relative;
  top: 0px!important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
}