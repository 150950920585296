.mainHeaderContent {
    padding: 20px 0;
}

.pageHeader{
  display: flex;
  align-items: center;
  padding: 0 23px;
  gap:20px;
}

.pageHeader h1{
  font-family: "Satoshi Medium";
  font-size:40px;
  font-weight: 500;
  line-height: 60px;
  margin-bottom:0;
  color:#000000;
}

.pageHeader span{
  font-size:40px;
  line-height: 60px;
  margin-bottom:0;
  font-weight: 300;
  margin-top: 0;
  color:#000000;
}
@media (max-width: 768px) {
  .pageHeader h1{
    font-size:25px;
  }
  
  .pageHeader span{
    font-size:25px;
  }
}