.mainDiv {
  display: flex;
  height: 100vh;
}
.contentDiv {
  margin-left: 11%;
  width: 88%;
  transition: margin-left 0.3s ease, width 0.3s ease;
  height: 100vh;
  overflow-y: auto;
  background-color: #f6f7fc;
}
.dataTable {
  align-items: center;
  border-radius: 10px;
  padding: 24px 24px;
  background-color: #f6f7fc;
}
.dataTableHeading {
  padding:0 24px;
  border-bottom:1px solid #032020;
}
.floor-overlay{
  margin-right: -16px;
 }
.pointer{
  cursor: pointer;
}
.overlay-image {
  position: relative;

}
.expanded {
  margin-left: 5%;
  width: 95%;
}

.maintable{
  background-color: white;
  padding: 30px 0;
  border-radius: 10px;
}
.maintable h2{
  font-family: "Satoshi Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #302030;
  padding-bottom: 16px;
  margin-bottom: 0;
}
.nen2580Class{

}
.wwsClass{

}
.epwClass{

}
.floorClassName{
  
}

.columnDropdown{
  right: 0;
  left: auto;
  transform: translateX(0);
  position: absolute;
  top: 100%;
  z-index: 1000;
    white-space: nowrap;
    max-width: 300px;
    overflow-x: hidden;
    box-sizing: border-box;
    width: 270px;
    max-height: 80vh;
    overflow-y: auto;
    margin-top:40px;
}
.selection-li-content{
  overflow: scroll;
  height: 334px;
  background: rgba(227, 246, 244, 0.2);
  border-radius: 20px 20px 0px 0px;
  margin-top: 15px;
}
.selection-container-content label{
  padding: 10px;;
}