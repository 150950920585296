.quote-scan-content{
  display:flex;
  gap:24px;
  padding:0 24px;
}

.quote-scan-content .main{
  display: flex;
  gap: 24px;
    width: 100%;
}
.quote-scan-content .Ist-block, .quote-scan-content .second-block, .quote-scan-content .third-block{
  background: #F6F7FC;
  padding: 16px 16px;
  width: 33%;
  border-radius:12px;
}

.quote-scan-content .Ist-block img, .quote-scan-content .second-block img{
  margin:auto;;
  display:block;
  margin-top:18px;
}

.quote-scan-content .third-block .img-block .image-wrapper img{
  margin-top: 18px;
  width:100%;
}

.quote-scan-content .third-block{
  background-color: #FFD3D3;
}

.tab-content .quote-scan-content p {
  margin-top: 8px;
  padding: 0; 
}

.img-block{
  display:flex;
  gap:5px;
}

.Ist-block input, .second-block input, .third-block input{
  float:right
}

.block-content h3{
  margin-top:26px;
  font-family: 'Satoshi Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0em;
  margin-bottom:0;
  color:#032020;
}

.block-content p{
  margin-top:8px;
  font-family: 'Satoshi Medium';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  color:#032020;
  opacity: 60%;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 22px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #032020;
  outline: none;
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: black;
}

.ext-int-block{
  position: relative;

}
.img-block{
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper {
  margin: 0 5px; 
}

.spacer {
  width: 10px;
}

@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .img-block{
    margin-top:20px;
  }
  .quote-scan-content .main{
    overflow-x: scroll;
  }
}