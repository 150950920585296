.formHeadListProject{
    padding:0 24px;
    border-bottom:1px solid #032020;
}
.formHeadListProject h2{
    font-family: "Satoshi Medium";
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #302030;
    padding-bottom: 16px;
    margin-bottom:0
}
