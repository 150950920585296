.sidebarDiv {
  width: 11%;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  font-family: "Satoshi Medium";
}
.sidebarToggleImg {
  width: 2rem;
}
.sidebarContent {
  margin-top: 5rem;
}
.sidebarContent ul {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
  padding: 0 24px;
}

.sidebarContent li a{
  color: #032020;
  opacity: .6;
  text-decoration-line: none;
}

.sidebarContent li a span{
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  opacity: .7;
  padding-top: 4px;
  text-align: left;
  white-space: nowrap;
}

.sidebarContent img {
  width: 1.5rem;
}
.sidebarContent a {
  text-decoration: none;
  margin-left: 1rem;
  /* color: #9a9a9a; */
}

.collapsed {
  width: 5%;
}

.hideText span {
  display: none;
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 10px;
}

.topList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.bottomList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.hamburgerButton {
  background: none;
  border: none;
  cursor: pointer;
  margin: 20px;
  padding: 0;
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 2;
}

.activeUser {
  color: orangered;
}

.active {
  color: black; /* Highlight background color */
  font-weight: bold; /* Optional */
}
.inactive {
 
}
