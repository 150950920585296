body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.create-bundle-btn span{
  padding:2px 15px 3px;
  font-size:14px;
  font-family: "Satoshi Medium";
  font-weight: 500;;
}
.btn.bundle-his-Btn {
  background: #004747;
  border-radius:50px;
  border:1px solid #004747;
}
.btn.bundle-his-Btn:hover{
  background: #004747;
  color:#fff;
}

.project-creation-container {
  position: relative;
}

.blur {
  filter: blur(1px);
}

.loader-overlay {
  position: relative;
  top: 700px;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.rows-content.error input[type="text"],
.rows-content.error input[type="email"],
.rows-content.error input[type="password"],
.rows-content.error select {
  border: 1px solid  #C70039;
}

.rows-content.error .select{
  border: 1px solid  #C70039;
}

.rows-content.error .date{
  border: 1px solid  #C70039;
}

.error-message {
  color:  #C70039;
  font-size: 12px;
}