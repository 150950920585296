.paginationControls {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  .traverseControls {
    display: flex;
    width: 30%;
    justify-content: flex-start;
    padding-left:24px;
  }
  .dropDownsSelect {
    display: flex;
    width: 70%;
    justify-content: flex-end;
  }
  .dropDownsSelect select {
    border: none;
    border-radius: 60px;
    background-color: #f6f7fc;
    padding: 6px 16px 6px 10px;
    color: #032020;
    text-decoration-line: none;
    font-family: "Satoshi Medium";
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .paginationButton {
    background-color: white;
    color: black;
    width:32px;
    height:20px;
    border: none;
    padding: 0 6px;
    margin: 0 3px;
    cursor: pointer;
  }
  
  .paginationButton:disabled {
    cursor: not-allowed;
  }
  
  .paginationInfo {
    font-weight: bold;
  }
  
  .rowsPerPageSelect {
    padding: 5px;
  }
  
  .pageNumbers {
    display: flex;
  }
  
  .pageNumber {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    border:none;
    width:32px;
    height:20px;
    padding: 0 6px;
    margin: 0 3px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-align: center;
    box-sizing: border-box;
    min-width: 32px;
    margin: 0 3px;
    color: rgba(0, 0, 0, 0.87);
    justify-content: center;
  }
  
  .activePage {
    background-color: rgba(0, 0, 0, 0.08);
    border: none;
    width:20px;
    height:20px;
    padding: 0 6px;
    margin: 0 3px;
    border-radius: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-align: center;
    box-sizing: border-box;
    min-width: 32px;
    margin: 0 3px;
    color: rgba(0, 0, 0, 0.87);
    justify-content: center;
  }
  
  .rowsPerPageSelect {
    padding: 5px;
  }
  