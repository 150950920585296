
.input-error {
    border: 2px solid #C70039;
}
  
.error-message {
    color: #C70039;
    font-size: 0.8rem;
    margin-top: 0.2rem;
}
.visualization-td input[type="checkbox"]{
    margin-left: 12px;
}

.babyModel-delete{
    margin-left:25px;
    border: 1px solid #032020;
}

.babyModel-delete span{
    padding: 4px 4px;
    text-align: left;
    margin-left:11px;
}
.delete-button {
    position: relative;
    display: inline-block;
}

.baby-model-img{
    width:100px;
    height:100px;
}

.visual{
    white-space: nowrap;
}
/* Initially hide the text */
.hover-text {
    display: none;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    color: #000; /* Text color */
    font-family: 'Satoshi Medium';
    border-radius: 3px;
    border:1px solid #000;
    white-space: nowrap;
    font-size: 15px;
}

/* Show the text on hover */
.delete-button:hover .hover-text {
    display: block;
}