.disabledButton {
  background-color: lightgray;
  color: white;
  pointer-events: none!important;
  cursor: default!important;
  margin-right: 35px;
}

.red {
  background-color: #e57373;
  color: white;
}

.green {
  background-color: #81c784;
  color: white;
}

.orange {
  background-color: #ffb74d;
  color: white;
}

.gray {
  background-color: lightgray;
  color: black;
}

.supAdminClass {
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  width: 55%;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  margin-right: 35px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height:27.38px;
}

.projectLeaderClass {
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  width: 55%;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  margin-right: 35px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height:27.38px;
}

.failedStatusBtn {
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  width: 55%;
  border-radius: 12px;
  display: flex;
  background-color: #ffb74d;
  gap: 10px;
  margin-right: 14px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height:27.38px;

}
.cautionImage {
  position: relative;
  left: -10px;
  top: 9px;
}
.activeBtnMargin{
  margin-right: 35px;
}
.failedStatusBtn a,
.supAdminClass a,
.projectLeaderClass a {
  font-family: "Satoshi Medium";
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-decoration: none;
  color: #fff !important;
}
@media (max-width: 1600px) {
  .failedStatusBtn, .supAdminClass,.projectLeaderClass{
    width: 85% !important;
  }
}
