.planning-content.drone.planning-content .first-row {
  display: flex !important;
  align-items: center!important;
  width: 100%!important;
  padding: 4px 24px;
}

 .ClientDatesLabel h5{
  margin-bottom:0;
}
.quote-details .first-row .rows-content, .quote-details .second-row .rows-content{
  width: 50%!important;
}


.PlanningErrorMessage {
  color: #C70039;
  font-weight: 700;
  font-size: 0.8rem;
  margin-top: 0.2rem;
  padding-left: 10px;
}
.planning-content{
  margin-bottom:16px;
}
.datemessage {
  color: #C70039;
  font-weight: 700;
  font-size: 0.8rem;
  /* padding: 6px 24px 16px 10px !important; */
}

.dateWarning{
  color: rgb(205, 54, 54);
  font-size: small;
}
.PlanningDatesDiv{
  padding: 0 24px;
}
.PlanningDatesDiv .first-date .planning-content,.PlanningDatesDiv .second-date .planning-content{
  margin-top:16px;
}
.PlanningDatesDiv .first-date .planning-headers label,.PlanningDatesDiv .second-date .planning-headers label{
  margin-top:30px;
}
.ClientDatesLabel{
  padding: 10px 25px 0 25px !important;
}

.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper{
  display: none;
}


@media only screen and (max-width: 768px) {

  .PlanningDatesDiv .second-date .planning-headers label{
   margin-top:75px;
  }
  .PlanningDatesDiv .first-date .planning-headers label{
   margin-top:0;
  }
  .dateWarning{
    font-size: 12px;
  }
}







