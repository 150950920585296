.OutsideInspectionCheckInputOthers{
  position: relative;
  top: 0px!important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 10px!important;
}

.OutsideInspectionCheckInput{
  position: relative;
  top: 3px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-top: 8px;
  
}
.OutsideInspectionCheckInputHead{
  position: relative;
  top: 3px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .check-options.inspection .check{
    width: 100% !important;
  }
}


