
.client {
    text-align: left;
}

.tableform {
    margin-bottom: 50px;
}

.labelform1 {
    display: flex;
    text-align: justify;
}

.inputform2 {
    width: 675px;
    margin-right: 10px;
    background-color: #f6f7fc;
 }