.timebar-content{
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: sticky;
  top: 25px;
}

.timebar .timebar-content .sub-content input[type="checkbox"] {
  appearance: none;
  border-radius: 50%;
  width: 22px;
  height: 20px;
  border: 1px solid #FF6B00;
  outline: none;
  position: relative;
  opacity: 15%;
  transform: scale(1.2);
}

@media only screen and (max-width: 768px) {
  .timebar{
    width:100%;
    height: 85px;
  }
  .timebar-content{
    flex-direction: row;
    overflow-x: scroll;
  }
  .timebar-content .sub-content label h6{
    font-size:12px;
    padding: 6px 8px;
  }
  .sub-content:not(:last-child)::after{
    top: calc(100% + -52px) !important;
    left: 23px !important;
    height: 2px !important;
    width: 100% !important;
    background-color: #FF6B00;
  }
  .timebar-content .sub-content label{
    padding:0;
    height:70px;
  }
  .timebar .timebar-content .sub-content input[type="checkbox"]{
    transform: scale(1.0);
  }
}