.language-row{
    width:30%;
}
.language-row .rows-content .select{
    font-family: 'Satoshi Medium';
    text-align: left;
    margin-top:8px;
    border:none;
    color:#032020;
    margin-top:8px;
    height:50px;
    padding: 0;
    background: #F6F7FC;
    font-size: 16px;
    -webkit-appearance: auto;
  
  }
  
  .language-row .rows-content .form-control {
      padding: 8px 16px 8px 16px;
      font-weight: 500;
  }
  
  .language-row .rows-content select {
    background: #F6F7FC url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6 8l4 4 4-4" stroke="%23333" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat;
    background-position: right 16px center;
    background-size: 23px; 
    padding:8px 16px 8px 16px;
    cursor: pointer;
    border-radius:5px;
  }
  @media only screen and (max-width: 768px) {
    .language-row{
      width:100%;
  }
  }