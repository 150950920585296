.deleteBtn {
  margin-left: 2rem;
}

.saveBtn {
  position: relative;
  left: 2%;
}

.userdiv{
  padding:0 24px;
}

.input-error {
  border: 2px solid #C70039;
}

.error-message {
  color: #C70039;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}