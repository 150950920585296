.quote-scan-content .third-block .img-block .image-wrapper img{
  margin-top: 18px;
  height:93px;
  object-fit: cover;
}

.SelecScanLabel{
  padding-left: 0!important;
}


@media only screen and (max-width: 768px) {
  .quote-scan-content .main{
    flex-direction: column;
  }
  .quote-scan-content .Ist-block, .quote-scan-content .second-block, .quote-scan-content .third-block{
    width:100%;
  }
}