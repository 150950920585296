.check-options.extras .check {
  padding: 11px 16px 11px 16px;
  height: auto;
}

.check-options.extras .check.first-item input {
  width: 47px;
}

.outExtraPlanextraInput {
  position: relative;
  top: 3px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 8px;
}
.outExtraPlanextraInputHead {
  position: relative;
  top: 5px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
}
.priceTH {
  text-align: center !important;
  width: 20% !important;
  padding: 0 10px !important;
}
.commissionOutTag {
  padding-left: 0 !important;
}
