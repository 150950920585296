.quote-details .rows-content input.form-control {
  padding: 8px 16px;
  border: none;
}

.quote-details .rows-content select.form-control {
  padding: 8px 16px;
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  border:none;
  color:#032020;
  background-color: #F6F7FC;
}


@media only screen and (max-width: 768px) {
  .quote-details .first-row, .quote-details .second-row{
    flex-direction: column;
  }
  .quote-details .first-row .rows-content, .quote-details .second-row .rows-content{
    width: 100% !important;
  }
}