.quote-inside-services-record .tab-content{
  padding:0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
  
.check-options.inside-service .check{
  width:100%;
  padding:11px 16px 11px 16px;
}

.check-options.inside-service .check.first-item input{
  width:31px;
}

.no-selection .tab {
  border: 1px solid  #C70039;
}

