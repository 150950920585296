.reportListDetailsContent{
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    gap: 12px;
}
.reportListDetailsContent .contentOptions{
    display: flex;
    gap: 10px;
}
.reportListDetailsContent .contentOptions .searchOption{
    display: flex;
    gap: 6px;
    border: 1px solid rgba(3, 32, 32, 0.15);
    padding: 5px 5px 3px 15px;
    height: 40px !important;
    width: 100%;
    border-radius: 5px;
}
.searchOption .searchInput{
    display: flex;
    gap:6px;
}
.searchOption .searchInput input{
    border:none;
}
.searchOption .searchInput input:focus{
    outline:none;
}
.searchInput img{
    margin-top:5px;
}
.contentButton{
    display: flex;
    gap: 6px;
    padding: 6px 24px;
    border: 1px solid rgba(3, 32, 32, 0.15);
    border-radius: 25px;
    height:40px;
}
.contentButton span{
     font-family: "Satoshi Medium";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #032020;
    opacity: 60%;
}

.dateIcon{
    margin-top:5px;
}

.btnDate,.btnClear{
    border: none;
    background-color: #fff;
}
.btnDate:focus,.btnClear:focus{
    outline:none;
}
.dateRange{
    position: absolute;
    left: 23%;
}
.rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 1;
}
.export .btn{
    padding: 7px 40px;
    border: 1px solid rgba(3, 32, 32, 0.15);
    border-radius: 25px;
    background-color: #f6f7fc;
}
.export .btn:focus{
    outline:none;
}
.export .btn span{
    font-family: "Satoshi Medium";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #032020;
    opacity: 60%;
}
