.PartnerLabel{
    padding-left: 0px!important;
    padding-top: 25px;
}
.DSPLabel{
    margin-top: 10px;
}
 .check-options.inspection{
    overflow-x: scroll!important;
}
