
.tableform {
    margin-bottom: 50px;
}

.labelform1 {
    display: flex;
    text-align: justify;
}

.inputform1 {
    width: 300px;
    margin-right: 10px;
    background-color: #f6f7fc;
}
.toggle-text{
    display: flex;
    position: relative;
    left: -10px;
  
  }
.enable-email span{
    margin-top: 12px;
    position: relative;
    top: 7px;
    left: 10px;
    color:#302030;
}
.inputform2 {
    width: 675px;
    margin-right: 10px;
    background-color: #f6f7fc;
}

.inputform4 {
    width: 280px;
    margin-right: 10px;
    background-color: #f6f7fc;
}

.textInput {
    width: 95%;
    height: 170px;
    margin-right: 10px;
    background-color: #f6f7fc;
}

.datepick {
    position: relative;
    left: 5px;
}