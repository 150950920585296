.tableInputs {
  width: 120px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #b7b6b6;
}
.extraServiceLabel{
  margin-bottom: 15px;
  margin-left: 5px;
}


.InServSectOffrInput {
  position: relative;
  top: 5px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 8px;
}
.InServSectOffrInputHead {
  position: relative;
  top: 3px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
}

.priceTH {
  text-align: center !important;
  width: 20% !important;
  padding: 0 10px !important;
}
.commissionOutTag {
  padding-left: 0 !important;
}