.quote-inside-summary-record .tab-content, .main-outside-content{
  padding:0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}   

.check-options.summary .check{
  width:100%;
  padding:11px 16px 11px 16px;

}

.no-selection .tab {
  border: 1px solid  #C70039;
}
  
.message {
  color: #C70039;
  margin-top: 5px;
}
