.quote-option-record{
  background-color: white;
  padding: 30px 0px;
  border-radius: 10px;
  margin-top: 20px;
}

.tab input:checked ~ .tab-content.record{
  margin-top:24px;
  max-height: 100%;
  overflow: visible;
}

.check-options{
  display:flex;
  gap:12px;
  margin-top: 18px;
}

.check-options.purpose .check{
  width:25%;
  padding:11px 16px 11px 16px;
 }

 .quote-option-record .tab-content {
  padding:0 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
 }

.check-header-title{
  display:flex;
  gap:16px;
}

.check-header-title-other input{
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #032020;
  margin-top: 8px;
  border:none;
  background-color: #F6F7FC;
}

.no-selection .tab {
  border: 1px solid  #C70039;
}
  
.message {
  color: #C70039;
  margin-top: 5px;
}
