
.check-options.extras .check{
  padding: 11px 16px 11px 16px;
  height: auto;
}



.OutsideExtraCheckInput{
  position: relative;
  top: 4px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 8px;
}
.OutsideExtraCheckInputHead{
  position: relative;
  top: 3px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
}
