.arrowAscendingStyling {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: -9px;
  transition: color 0.2s ease-in-out;
  color: grey; /* Default light color */
}

.arrowDescendingStyling {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  color: grey; /* Default light color */
}

.arrowAscendingStyling.active svg,
.arrowDescendingStyling.active svg {
  color: #000 !important; 
  font-weight: bold;/* Change to red when clicked */
}

.tableArrow {
  display: flex;
  flex-direction: column; /* Stack arrows vertically */
  align-items: center;
}