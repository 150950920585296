.main-outside-content .left-content .header h3{
  font-family: 'Satoshi Medium';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0em;
  margin-bottom:0;
  color:#032020; 
}

.main-outside-content .left-content .header p{
  font-family: 'Satoshi Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-top:0;
  margin-bottom:8px;
  color:#032020;
  opacity: 60%;
  padding:0;
}

.main-outside-content{
  display:flex;
  gap:24px;
  flex-direction: row;;
  margin-top:24px;
}

.right-content{
   margin-top:7px;
}
  
.outside-model{
  display:flex;
  gap:50px;
}

.model {
  display: flex;
  gap:16px;
  flex-direction: column;
 }
  
 .check {
  display: flex;
  gap:12px;
  border:1px solid rgba(3,32,32,0.15);
  background-color: #f6f7fc;
  padding:16px 24px 16px 18px;
}
  
.check input {
  padding: 0;
  margin-bottom: 0;
  cursor: pointer;
}
  
.model .check{
  width:416px;
  padding:11px 16px 11px 16px;
}

.check label {
  font-family: 'Satoshi Medium';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0em;
  padding:0;
  color:#032020;
}

.check-header label{
  font-family: 'Satoshi Medium';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color:#032020;
  padding:0;
}
  
 .outside-model .model .check label{
  padding:0;
 }

.right-content img{
   width:678px;
 }

.no-selection .tab {
  border: 1px solid  #C70039;
}

.message {
  color: #C70039;
  margin-top: 5px;
}

.CheckInput{
  position: relative;
  top: 0px!important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
}