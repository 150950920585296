.inviteDiv1 {
  width: 600px;
  background-color: #f6f7fc;
  position: relative;
  left: 20px;
  border-radius: 1rem;
  margin-top: 20px;
}

.inviteDiv2 {
  width: 600px;
  height: 450px !important;
  background-color: #f6f7fc;
  position: relative;
  left: 20px;
  margin-top: 30px;
  border-radius: 1rem;
}

.inviteDiv3 {
  margin-bottom: 30px;
}

.tableStyle {
  width: 550px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.labelform1 {
  display: flex;
  text-align: justify;
} 

.inputFields{
  width:200px !important;
  margin-left:5px;
}
.saveBtn{
  margin-top: 20px;
  margin-left:5px;
}

.invite-left-content{
  margin-top: 24px;
  background-color: #f6f7fc;
  display: flex;
  justify-content: space-between;
  padding:12px 14px;
  border-radius:10px;
}

.invite-left-content .profile{
  display: flex;
  gap: 24px;
}


.invite-left-content .profile h4{
font-family: 'Satoshi Medium';
font-size: 16px;
font-weight: 500;
line-height: 26px;
letter-spacing: 0em;
color:#032020;
margin-bottom:0;
}

.invite-left-content .profile p{
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color:#032020;
  opacity:60%;
  margin-bottom:0;
}

.invite-left-content .profile-buttons{
  display: flex;
  gap: 24px;
}

.invite-left-content .profile-buttons img{
  position: relative;
  bottom: -3px;
  cursor: pointer;
}

.input.form-control.searchBar{
  border:none;
  outline:none;
}

.invite-left-content .profile-buttons p{
  margin-bottom: 0;
  border-radius: 50px;
  background: #fff;
  padding: 7px 16px 7px 16px;
  font-family: 'Satoshi Medium';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color:#032020;
}

@media only screen and (max-width: 768px) {
  .invite-left-content {
    flex-direction: column;
  }
  .invite-left-content .profile-buttons {
    margin-top:11px;
  }
  .invite-left-content .profile p{
   font-size: 9px;
  }
  
}