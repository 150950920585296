
.form-head.quote-detail {
  border-bottom: 1px solid #032020;
  padding: 0 24px;
}

.quote-content{
  padding:0 24px;
}
  
.quote-details .first-row, .quote-details .second-row {
  display: flex;
  gap: 16px;
  padding: 10px 0;
}

.quote-details .first-row .rows-content, .quote-details .second-row .rows-content {
  width: 100%;
}
  
.quote-details .rows-content label {
  font-family: 'Satoshi Bold';
  color: #032020;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  padding:0;
}

.quote-details .rows-content input.form-control {
  padding: 8px 16px;
  border: none;
  margin-top: 10px;
}

.quote-details .rows-content input {
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #032020;
  background-color: #F6F7FC;
}

.quote-details .rows-content select.form-control {
  padding: 8px 16px;
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  margin-top:10px;
  letter-spacing: 0em;
  text-align: left;
  border:none;
  color:#032020;
  background-color: #F6F7FC;
}

.quote-details .rows-content .select{
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-top:8px;
  border:none;
  color:#032020;
  background-color: #F6F7FC;
  margin-top:8px;
  height:50px;
  padding:0;
}

.input-error input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
}

.no-selection .tab {
  border: 1px solid  #C70039;
}

.message {
  color: #C70039;
  margin-top: 5px;
}