.quote-outside-record .tab-content {
  padding:0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
  
.check-options.inspection .check{
  width:416px;
  padding:11px 16px 11px 16px;
}

.no-selection .tab {
  border: 1px solid  #C70039;
}
