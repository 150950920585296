

.selectionLiContent li label{
    display: flex;
    gap: 16px;
    color: #717171;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 18.9px;
    font-family: 'santoshi Medium';
    font-weight: 400;
    white-space: nowrap;

}
.selectionLiContent li label span{
  display: inline-block;
  max-width: 33ch; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.selectionLiContent li label span:hover::after {
  content: attr(title);  
  position: absolute;
  left: 0;
  top: 100%;
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
}
.selectionLiContent{
    overflow-y: scroll;
    height: 155px;
    overflow-x: hidden;
    margin-top: 15px; 
}
.selectionLiContent::-webkit-scrollbar {
  width: 8px; 
  height: 8px; 
}
/* Scrollbar styling for Firefox */
.selectionLiContent {
  scrollbar-color:  #D9D9D9; 
  scrollbar-width: 4px; 
}

.selectionLiContent li label input[type="checkbox"]::before{
  content: "\2713";
  display: block;
  position: absolute;
  left: 42%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #fff;
  width: 6px;
  top:12px;
}

.mainSelectionLIContent{
  background: rgba(227, 246, 244, 0.2);
  border-radius: 20px 20px 0px 0px;
  padding:9px;
}

.mainSelectionLIContent::-webkit-scrollbar {
  width: 2px; 
}

.selectionLiContent li label input{
 position: relative;
 top:2px;
}


.selectionLiContent::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  .selectionLiContent::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
  
  .selectionLiContent::-webkit-scrollbar-track {
    background: #d9d9d9;
  }
  .selectionContainerContent{
    background-color: #45C0B2;
    border-radius: 20px 20px 0 0;
    padding: 10px;
    padding-left: 15px;
    color: #fff;
    margin-bottom: -1px;
    display: flex;
    justify-content: space-between;
    border-bottom: 14px solid #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  .selectionContainerContent label{
    padding:0 0 8px 0;
    font-size: 16px;
    line-height: 21.6px;
    font-family: 'santoshi Medium';
    font-weight: 400;
  }
  .selectionContainerContent img.upArrow{
    position: relative;
    top: 1px;
    width: 13px;
    height: 13px;
  }
  .selectionContainerContent img.downArrow{
    position: relative;
    top: 11px;
    width: 21px;
    height: 21px;
  }
.selectionContainer ul{
  list-style-type: none;
    padding: 0px;
    margin: 0;
    border-radius: 20px;
    background-color: #fff;
    position: relative;
    border-top: 1px solid #fff;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    top: -26px;
    z-index: 2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
  .resetSelection{
    color: #45c0b2;
    border: none;
    background: none;
    font-size: 14px;
    font-family: "Satoshi Medium";
    margin-top: 8px;
    margin-left: 7px;
    font-weight: 400;
    padding:6px 12px;
    line-height:18.9px;
    border-radius: 50px;
    
  }
  .resetSelection:focus,
  .resetSelection:active {
    outline: none;
    border: none;
}
.reset-selection {
  --bs-btn-active-color: none!important;
   --bs-btn-active-bg: none!important;
}

  .resetSelection::after{
    content:'';
    position: absolute;
    left: 12px;
    width: 71%;
    height: 1px;
    background-color:  rgba(3, 32, 32, 0.15);
    margin-top: 22px;
  }

 
  .searchBoxFilter{
    padding: 6px;
    margin-top:8px;
    margin-left: 7px;
    display: flex;
  }
  .searchBoxFilter img{
    color:#f2f2f2;
    width: 15px;
    height: 15px;
    margin-top: 4px;
  }

  .searchBoxFilter input[type=text] {
    border: none;
    background-color: none;
    outline: 0;
    font-family: "Satoshi Medium";
    font-size: 14px;
    font-weight: 400;
    line-height: 18.9px;
    color:#032020;
    opacity: 60%;;
  
  }
  
  .searchBoxFilter input[type=text]:focus {
    border: none;
    background-color: none;
    outline: 0;
    width: 300px;
    height: 30px;
  }
  .selectionFilterButton{
    cursor: pointer;
    border: none;
    background: none;
    position: absolute;
  }
  .selectionDropdown.elementDropdown{
    left:-205px;
    position: absolute;
    top: 100%;
    z-index: 1;
    box-sizing: border-box;
    width: 270px;
    /* overflow-y: auto; */
  }
  .selectionDropdown.typeDropdown{
    left:-83px;
    position: absolute;
    top: 100%;
    z-index: 1;
    box-sizing: border-box;
    width: 270px;
    /* overflow-y: auto; */
  }
  .selectionDropdown.locationDropdown{
    left:107px;
    position: absolute;
    top: 100%;
    z-index: 1;
    box-sizing: border-box;
    width: 270px;
    /* overflow-y: auto; */
  }
  .selectionDropdown.elementDropdown .selectionContainer,.selectionDropdown.typeDropdown .selectionContainer,.selectionDropdown.locationDropdown .selectionContainer{
    position: relative;
    padding-top: 10px;
  }

  @media screen and (min-width: 2560px) {
    .selectionLiContent{
      height: 258px;
  }
  }
  
  @media screen and (min-width: 3840px) {
    .selectionLiContent{
      height: 258px;  
  }
  }
  
  @media screen and (min-width: 5120px) {
    .selectionLiContent{
      height: 258px; 
  }
  }
  
  