.imgWidth {
  width: 30px;
  height: 30px;
}

.clickedRow{
  background:#d3d3d3;
}

 .rowWrapper{
  border-radius: 12px;
    overflow: hidden;
    background-color: #f2f2f2;
 }
 .innerTable tr{
  border-bottom:none !important;
 }
 .listSubAccordian{
  border: none!important;
 }

.exploreButton{
  color: white;
  padding: 7px 10px;
  border-radius: 12px;
  border: none;
  display: flex;
  background-color: #FE6B18;
  gap: 10px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.exploreButton a, .exploreInActive a{
  font-family: "Satoshi Medium";
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-decoration: none;
  color: #fff !important;
}
.rowWrapper table tbody tr td button{
  color: white;
  padding: 7px 10px;
  border-radius: 12px;
  border: none;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: "Satoshi Medium";
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-decoration: none;
  width:110.89px;
}
.exploreInActive{
  color: white;
  border-radius: 12px;
  padding: 7px 10px;
  cursor: not-allowed;
  pointer-events: none;
  display: flex;
  gap: 10px;
  justify-content: center;
  border: none;
  white-space: nowrap;
  background-color: #d3d3d3;
}
.rowWrapper table tr{
  border-bottom: 2px solid #fff;
  background-color: #f2f2f2;

}
.rowWrapper table tr td{
  padding:2px 20px!important;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 150px;
}
.rowWrapper table tr td:first-child {
    width:5%;
}