.dataTableHeading {
    margin-top: 10px;
    padding: 0;
    font-size: 2rem;
  }
  .dataTableFilters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px 24px;
    gap: 12px; 
   }
  .filterImages{
    box-sizing: border-box;
    vertical-align: middle;
  }
  .searchFilter {
    display: flex;
    justify-content: center;
    gap: 6px;
    border: 1px solid rgba(3, 32, 32, 0.15);
    padding: 5px 5px 3px 15px;
    height: 40px !important;
    border-radius: 5px;
  }
  .searchFilter input {
    border: none;
  }
  .searchFilter input:focus {
    outline: none; 
    border: none; 
}
  .statusFilter {
    display: flex;
    width: 10%;
    justify-content: center;
    border-radius: 53px;
    border: 1px solid #ededed;
    margin-left: 1.5rem;
  }
  .statusFilter select {
    border: none;
    margin-top: 12px;
  }
  
  .roleFilter {
    display: flex;
    width: 10%;
    justify-content: center;
    border-radius: 53px;
    border: 1px solid #ededed;
    margin-left: 1.5rem;
  
  }
  .roleFilter select {
    border: none;
    margin-top: 12px;
  }
  .dateFilter {
    display: flex;
    width: 10%;
    justify-content: center;
    border-radius: 53px;
    border: 1px solid #ededed;
    margin-left: 1.5rem;
  }
  .dateFilter button {
    background-color: white;
    border: none;
  }
  .clearFilter {
    display: flex;
    width: 10%;
    justify-content: center;
    border-radius: 53px;
    border: 1px solid #ededed;
    margin-left: 1.5rem;
  }
  .clearFilter button {
    background-color: white;
    border: none;
  }
  .exportFilter {
    display: flex;
    width: 10%;
    justify-content: center;
    border-radius: 53px;
    border: 1px solid #ededed;
    margin-left: 1.5rem;
  }
  .exportFilter button {
    background-color: white;
    border: none;
  }
  .createNew {
    display: flex;
    width: 40%;
    justify-content: end;
  }
  .createNew button{
    border-radius: 53px;
    width: 150px;
    border: none;
    background-color: orangered;
    color:white;
    font-weight: bold;
  }
  .createNew img{
  padding-right:2px;
  }