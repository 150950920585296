.check-header-title{
  display:flex;
  gap:16px;
  padding-bottom: 10px;
}

.OutsidePurposeCheckInput{
    position: relative;
    top: 0px!important;
    width: 20px !important;
    height: 20px !important;
    padding: 10px;
  }
.OutsidePurposeCheckInputOthers{
    position: relative;
    top: 0px!important;
    width: 20px !important;
    height: 20px !important;
    padding: 10px;
    margin-bottom: 10px!important;
}


@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .quote-inside-services-record .check-options{
    overflow-x: scroll;
  }
}