.red {
  background-color: #e57373;
}
.orange {
  background-color: #ffb74d;
}
.green {
  background-color: #81c784;
}
.failed {
  background-color: #ffb74d;
}
.grey {
  background-color: #D3D3D3;
}
.disabled{
  background-color: #D3D3D3;
}
.notStarted{
  background-color: #D3D3D3;
}
.red,.orange,.green,.failed,.grey,.disabled,.notStarted{
  width:70%;
}
@media (max-width: 1600px) {
  .red,.orange,.green,.failed,.grey,.disabled,.notStarted{
    width:100%;
  }
}
@media screen and (min-width: 2560px) {
  .red,.orange,.green,.failed,.grey,.disabled,.notStarted{
    width:40%;
  }
}
@media screen and (min-width: 3840px) {
  .red,.orange,.green,.failed,.grey,.disabled,.notStarted{
    width:25%;
  }
}
@media screen and (min-width: 5120px) {
  .red,.orange,.green,.failed,.grey,.disabled,.notStarted{
    width:25%;
  }
}