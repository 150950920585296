
.tableDspEdit {
  position: relative;
  left: 20px;
}

.outsideIteminputfields {
  width: 100%;
  padding: 10px;
  background-color: #f6f7fc;
  gap: 2px;
  border: 1px solid rgba(3, 32, 32, 0.15);
  display: flex;
  white-space: normal;
  padding: 10px;
}
input[type="checkbox"] {
  position: relative;
  top: 5px;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
}

.tableInputs {
  width: 120px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #b7b6b6;
}

.AddRowButton {
  padding: 4px;
  border: none;
  width: 52px;
  border-radius: 2px;
  background: #f3f3f3;
  img {
    padding: 0px 0px 3px 0px;
  }
}
.RemoveRowButton {
  padding: 6px;
  border: none;
  width: 52px;
  border-radius: 2px;
  background: #f3f3f3;
  img {
    padding: 0px 5px 2px 0px;
  }
}

.OutsideInputCheckInputOthers{
  position: relative;
  top: 13px!important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 10px!important;
}
@media only screen and (max-width: 768px) {
  .check-options{
    flex-direction: column;
  }
  .check-options.summary .check {
    width:100%;
  }
  .model .check{
    width:100%;
  }
  .outside-model{
    flex-direction: column-reverse;
  }
  .outside-model .right-content img{
    margin-top:0!important;
  }
  .check-options.purpose .check {
    width: 100%!important;;

  }
  .check-options.extras .check{
    width: 100%!important;
  }
  .tableDspEdit{
   margin-top:14px;
   padding:0;
   overflow-x: scroll;
  }
  .outsideIteminputfields{
    font-size:11px;
  }
}