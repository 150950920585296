.contentHeader {
    align-items: center;
    background-color: white;
    padding: 20px 0;
    font-family: "Satoshi Medium";
  }
  
  .contentHeader button {
    align-items: center;
    background: none;
    border: 1px solid #ddd;
    border-radius: 53px;
    cursor: pointer;
    font-size: 1rem;
    color: #ff6b00;
    width: auto;
    padding: 6px 12px;
  }
  
  .contentHeaderHeading {
    color: #032020;
    font-family: Satoshi Medium;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 0 36px;
  }
  .user{
    margin-top: 10px;
    padding: 0 36px;
  }
  .user button span{
    color: #ff6b00;
    font-family: "Satoshi Medium";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: left;
  }