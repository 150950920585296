.sub-content:not(:last-child)::after {
  content: "";
  position: absolute;
  top: calc(105% + -16px);
  left: 5%;
  height: 39px;
  width: 2px;
  background-color: #FF6B00;;
}

.timebar .timebar-content .sub-content input[type="radio"] {
  appearance: none;
  border-radius: 50%;
  width: 22px;
  height: 20px;
  border: 1px solid #FF6B00;
  outline: none;
  position: relative;
  opacity: 15%;
  transform: scale(1.2);
  background-color: #032020;
}
