.planning-content .rows-content .date {
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  color: #032020;
  background-color: #F6F7FC;
  height: 50px;
  padding: 0;
}

.date-range{
  padding: 8px 16px;
  border: none;
  background: none;
}

.no-selection .tab {
  border: 1px solid  #C70039;
  border-radius:5px;
  padding: 14px 0;
}


.message {
  color: #C70039;
  padding-top: 6px;
  font-weight: 700;
  font-size: 0.8rem;
  margin-top:0.2rem;
  padding-left: 6px;

}
.datemessage {
  color: #C70039;
  font-weight: 700;
  font-size: 0.8rem;

  
}
.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper{
  display: none;
}


@media only screen and (max-width: 768px) {
  .quote-planning-record .tab-content{
    display: flex;
    gap:0;
  }
  .planning-content .first-row{
    padding:2px 0;
  }
}