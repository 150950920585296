
  /* projectleader details */

.projectleader-details .rows-content select{
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color:#032020;
  background-color: #F6F7FC;
  border-radius:5px;
}
 
.required{color:red;} 
  
@media only screen and (max-width: 768px) {

.main-wrap .portfolio{
  position: relative;
  margin-left:0;
  left:0;
}

.quote-body{
  display: block;
}

.project-leaderdetails-content .first-row .rows-content,.project-leaderdetails-content .second-row .rows-content {
  padding-top:12px;
  width: 100%!important;
}
  
.project-leaderdetails-content .first-row, .project-leaderdetails-content .second-row{
  display: block!important;
  padding: 0 !important;
  margin:0;
  height:auto!important;
}

.pageHeader h1 {
  font-size:25px;
}

.projectleader-details h2{
    font-size:18px;
  }
}