
.outsideModelRow {
  width: 100% !important;
}
.Header3d{
  margin-bottom:10px!important;
}
.commissionOutTag{
  padding-left: 0!important;
}
.outSelOffrInput {
  position: relative;
  top: 5px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 8px;
}
.priceTH {
  text-align: center !important;
  width: 20% !important;
  padding: 0 10px !important;
}