
.edit-check-header .check-options{
  margin-top:0;
}

.tableInputs {
  width: 120px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #b7b6b6;
}
.extraServiceLabel{
  margin-bottom: 15px;
  margin-left: 5px;
}

.InProdSectOffrInput {
  position: relative;
  top: 0px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 0px!important;
}
.InProdSectOffrInputHead {
  position: relative;
  top: 3px !important;
  bottom: 10px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
  margin-bottom: 15px;
}